
.new-pokemon {

  .pokemon-attribute {
    margin: 10px auto;
  }

  .checkboxes *,
  .is-shiny *,
  .is-dead * {
    width: auto;
    display: inline;
  }

  .checkboxes {
    display: flex;

    .checkbox-and-label {
      width: 50%;
      input {
        margin-right: 5px;
      }
    }
  }

  .buttons {
    margin: 20px auto 0;
    display: flex;
  }
}
