
.box-section {
  width: auto;
  display: flex;
  align-items: center;
  overflow: scroll;

  &.wrap {
    flex-wrap: wrap;
  }

  &.no-wrap {
    flex-wrap: nowrap;
  }

  .pokemon-container {
    &:last-child {
      padding-right: 20px !important;
    }
    margin-left: 5px;

    .pokemon {
      border-radius: 50%;
    }
  }
}
