
.display-section {
  display: flex;
  justify-content: center;
  align-items: center;

  .party {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}
