
.pokemon-party-tracker {
  width: 100vw;
  height: 100vh;
  display: grid;

  grid-template-rows: auto var(--box-height);
  grid-template-columns: 200px auto;
  grid-template-areas:
    "sidebar-section display-section"
    "sidebar-section box-section"
  ;

  .sidebar-section {
    grid-area: sidebar-section;
    max-width: 200px;
  }

  .display-section {
    grid-area: display-section;
  }

  .box-section {
    grid-area: box-section;
  }

  img {
    image-rendering: auto;
    image-rendering: crisp-edges;
    image-rendering: pixelated;
  }

  .hide-borders {
    border: 1px solid transparent;
  }

  .show-borders {
    border: 1px solid black;
  }
}
