
.sidebar-section {
  max-height: 100vh;
  overflow: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  align-items: center;

  hr {
    border: 1px solid darkslategrey;
  }

  .control-buttons {
    display: flex;
  }

  .config-sections {
    width: 90%;
  }

  .config-section {

    button, input:not([type='checkbox']) {
      box-sizing: border-box;
      width: 100%;
    }
  }

  .bottom-section {
    width: 90%;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input:not([type='checkbox']):not([type='radio']), select {
    width: 90%;
  }

  label {
    font-weight: bold;
    display: block;
    margin: 2px;
  }

  button {
    padding: 5px 10px;
    margin: 10px auto;

    &.confirm-button {
      background: aquamarine;
      width: 50%;
    }

    &.danger-button {
      background: indianred;
      width: 50%;
    }

    &.top-level {
      background-color: aquamarine;
    }
  }

  .displayed-section {
    width: 90%;
    margin: 0 auto;
  }

  .other-config {
    .input-section {
      margin: 8px auto;
    }
  }
}
