
.gen-selector {
  position: relative;

  select {
    display: block;
    margin: 15px auto;
  }

  button {
    width: 100% !important;
  }
}
