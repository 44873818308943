
.party-editor {

  width: 100%;
  padding: 20px 0;

  .editor-entry {
    width: 90%;
    margin: 5px auto;

    .editor-name {
      display: flex;
      justify-content: space-between;

      .show-fields {
        cursor: pointer;
        padding: 1px 2px;
        &:hover {
          font-weight: bold;
        }
      }
    }

    .editor-fields {
      margin: 10px auto;

      .editor-field {
        margin: 10px auto;
      }

      .is-shiny,
      .is-dead {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        input {
          width: 20px;
        }
        label {
          margin-right: 10px;
        }
      }
    }
  }
}
