
.pokemon-container {
  background-repeat: no-repeat;

  .pokemon-container-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 1px;
  }
}


.pokemon {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .nickname, .crossout {
    pointer-events: none;
  }

  &.dragged-over .sprite,
  &.dragged-over .crossout {
    filter: opacity(40%);
  }

  .label {
    //background: white;
    padding: 3px 6px;
    margin: 5px;
    border-radius: 2px;
    color: white;
    text-shadow:
      -1px -1px 1px black,
      0px -1px 1px black,
      1px -1px 1px black,
      -1px -1px 1px black,
      0px -1px 1px black,
      1px -1px 1px black,

      -1px 0px 1px black,
      0px 0px 1px black,
      1px 0px 1px black,

      -1px 1px 1px black,
      0px 1px 1px black,
      1px 1px 1px black,

      -1px 1.5px 1px black,
      0px 1.5px 1px black,
      1px 1.5px 1px black,

      -2px 2px 1px black,
      -1px 2px 1px black,
      0px 2px 1px black,
      1px 2px 1px black,
      2px 2px 1px black,

      -2px 2px 1px black,
      -1px 2px 1px black,
      0px 2px 1px black,
      1px 2px 1px black,
      2px 2px 1px black
    ;
  }

  .sprite {
    overflow: hidden;
    position: absolute;
  }

  .shiny {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 40px;
  }

  .level {
    position: absolute;
    top: 0;
    right: 0;
  }

  .nickname {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .hold-item {
    background: darkgray;
    border: 1px solid #5f5f5f;
    padding: 1px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .crossout {
    z-index: 2;
    width: 100%;
  }
}
